<template>
    <div class="shop_main">
        <div class="shop_search_menu">
            <a href="javascript:void(0)" @click="$router.replace('/index')">首页</a>
            <i class="el-icon-arrow-right"></i>
            <span class="on">帮助中心</span>
        </div>

        <img :src="'./img/banner_help.png'" class="page_banner" />

        <div class="shop_help_new">
            <div class="help_class">
                <div class="title">帮助中心</div>

                <div class="info">
                    <a href="javascript:void(0)" :class="{on: listQuery.category === '1'}" @click="toChangeCLass('1')">新手指南</a>
                    <a href="javascript:void(0)" :class="{on: listQuery.category === '2'}" @click="toChangeCLass('2')">问题解答</a>
                    <a href="javascript:void(0)" :class="{on: listQuery.category === '3'}" @click="toChangeCLass('3')">规则协议</a>
                </div>
            </div>

            <div class="shop_help">
                <div class="head">
                    <h3 class="title" v-if="listQuery.category === '1'">新手指南</h3>
                    <h3 class="title" v-if="listQuery.category === '2'">问题解答</h3>
                    <h3 class="title" v-if="listQuery.category === '3'">规则协议</h3>

                    <div class="contact">
                        <span class="text">无法解决您的问题？请联系</span>
                        <span class="text phone">客服热线：<span class="number">{{consumerPhone}}</span></span>
                    </div>
                </div>

                <div class="content" v-loading="isRequestLoading" style="min-height: 308px">
                    <ul>
                        <li v-for="(item, index) in listData" :key="index" class="noBorder">
                            <a href="javascript:void(0)" @click="$router.push({path: '/shop/article/detail', query: {type: '1', id: item.id}})">
                                <span class="title"><span> · </span>{{item.title}}</span>
<!--                                                            <span v-if="item.createdAt" class="time">{{item.createdAt.split(' ')[0]}}</span>-->
                            </a>
                        </li>
                    </ul>
                </div>
                <pagination :size="listQuery.pageSize" :total="pages.total" :current="listQuery.page" :pages="pages.pages" @pageChange="pageChange"/>
            </div>
        </div>
    </div>
</template>

<script>
  import {mapState} from "vuex";
  import {getArticle} from '../../api/shop/article'
  import {consumerPhone} from '../../utils/config'
  const pagination = () => import('../../components/pagination/shop')
  export default {
    name: "shopHelp",
    data () {
      return {
        listQuery: {
          type: '1',
          category: '1',
          page: 1,
          pageSize: 18
        },
        pages: {
          total: 0,
          pages: 0
        },
        isRequestLoading: false,
        listData: [],
        consumerPhone: consumerPhone
      }
    },
    computed: {
      ...mapState({
        listKeepAlive: state => state.app.listKeepAlive,
      })
    },
    activated () {
      if (this.listKeepAlive.isInitPageNo) {
        this.$store.commit('changeScrollTop');
        this.listQuery.page = 1
      }
      if (this.listKeepAlive.isQueryList) {
        this.getList()
      }
    },
    methods: {
      getList () {
        this.isRequestLoading = true
        getArticle(this.listQuery).then(res => {
          this.isRequestLoading = false
          if (res.code === 0) {
            this.listData = res.data.records
            this.pages.total = res.data.total
            this.pages.pages = res.data.pages
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      },
      pageChange (e) {
        this.$store.commit('changeScrollTop');
        this.listQuery.page = e.current
        this.listQuery.pageSize = e.size
        this.getList()
      },
      toChangeCLass (e) {
        this.listQuery.page = 1
        this.listQuery.category = e
        this.getList()
      }
    },
    components: {
      pagination
    }
  }
</script>
